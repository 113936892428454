import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`Single Arm KB Front Squats 3×12/arm`}</p>
    <p>{`Hamstring Curls 3×12`}</p>
    <p>{`then, 4 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`15-DB Hang Power Cleans (40/25’s)`}</p>
    <p>{`50ft DB Front Rack Walking Lunge (40/25’s)`}</p>
    <p>{`15-GHD Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no instructor led bootcamp this Wednesday but we will
have a workout available on the white board. `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday (rest day) we will be filming a wod on the back lot
during the 12:00 class.  If you can make it please let us know!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      